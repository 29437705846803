import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import { Table, TableColumn, Notification, Select, Option } from "element-ui";
import VCalendar from "v-calendar";
import Vuelidate from "vuelidate";
import axios from "axios";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";

import Logger from "@/utils/logger";
import getUrlParams from "@/utils/getUrlParams";

import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import "flag-icon-css/sass/flag-icon.scss";

require("./styles/theme/index.css");

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: `https://${process.env.VUE_APP_SENTRY}@sentry.utraff.info/7`,
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
    environment: process.env.NODE_ENV,
  });
}

Vue.config.productionTip = false;

Vue.use(VueApexCharts);
Vue.component("ApexChart", VueApexCharts);

Vue.use(Table);
Vue.use(TableColumn);

Vue.use(VCalendar);

Vue.use(Vuelidate);
Vue.use(Logger);

Vue.use(Select);
Vue.use(Option);

const accessToken = getUrlParams(window.location.search).token;
if (accessToken) {
  localStorage.clear();
  localStorage.setItem("token", `Token ${accessToken}`);
  store.dispatch("authToken", { token: `Token ${accessToken}` }).then(() => {
    router.push("/");
  });
}

const token = localStorage.getItem("token");
if (token) {
  axios.defaults.headers.common.Authorization = token;
}

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      store.dispatch("logoutRequest").then(() => {
        if (router.currentRoute.name !== "login") {
          Notification.error({
            title: "Ошибка авторизации",
            message: "Пожалуйста, выполните вход под вашей учетной записью",
          });
          router.push("login");
        }
      });
    }
    return Promise.reject(error.response);
  }
);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
