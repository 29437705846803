<template>
  <transition name="modal">
    <div class="modal-mask" v-if="shown">
      <div class="modal-wrapper">
        <div
          class="modal-underlay"
          @click="!unclosable && $emit('update:shown', false)"
        />
        <div class="modal-container">
          <button
            v-if="!unclosable"
            class="modal-close-btn"
            @click="$emit('update:shown', false)"
          />
          <div class="modal-header">
            <slot name="header"> </slot>
          </div>
          <vue-custom-scrollbar class="modal-body">
            <slot name="body"> </slot>
          </vue-custom-scrollbar>

          <div class="modal-footer">
            <slot name="footer">
              <button @click="$emit('update:shown', false)">OK</button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import vueCustomScrollbar from "vue-custom-scrollbar";

export default {
  name: "BaseModal",
  components: {
    vueCustomScrollbar,
  },
  props: {
    shown: {
      type: Boolean,
      default: false,
      required: true,
    },
    unclosable: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/variables";

.modal {
  &-close-btn {
    display: block;
    position: absolute;
    right: 30px;
    top: 40px;
    width: 22px;
    height: 22px;
    border: none;
    background-image: url(../assets/icons/close.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-color: $c-white;
    opacity: 0.85;

    &:hover {
      opacity: 1;
    }
  }

  &-link {
    color: $c-primary;
    font-size: 1rem;
  }

  &-underlay {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
  }

  &-mask {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: table;
    transition: all 0.1s ease;
  }

  &-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  &-container {
    position: relative;
    width: 100%;
    max-width: 585px;
    margin: 0px auto;
    padding: 40px 37px 30px 45px;
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0px 21px 63px rgba(139, 172, 190, 0.22);
    transition: all 0.1s ease;
  }

  &-header {
    h3 {
      margin-top: 0;
    }

    h2 {
      font-size: 1.8rem;
    }
  }

  &-body {
    max-height: 70vh;
    padding-right: 30px;
    margin: 20px 0;
  }

  &-footer {
    font-size: 1rem;
  }

  &-default-button {
    float: right;
  }

  &-enter {
    opacity: 0;
  }

  &-leave-active {
    opacity: 0;
  }

  &-enter &-container,
  &-leave-active &-container {
    transform: scale(1.15);

    .modal-body {
      opacity: 0;
    }
  }
}

// plugin styles override

.ps__thumb-x,
.ps__thumb-y {
  background-color: $c-scrollbar !important;
  right: 0px !important;
  // opacity: 1 !important;
}

.ps__thumb-y {
  width: 9px !important;
}

.ps__rail-y {
  background-color: $c-scrollbar-underlay !important;
  width: 9px !important;
  // opacity: 1 !important;
  border-radius: 100px;
}
</style>
