import axios from "axios";
import apiUrl from "@/utils/apiUrl";

const managerRequest = () =>
  new Promise((resolve, reject) => {
    axios({
      url: apiUrl("manager/"),
      method: "GET",
    })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });

const notificationsRequest = ({ commit }) =>
  new Promise((resolve, reject) => {
    axios({
      url: apiUrl("notification/"),
      method: "GET",
    })
      .then((resp) => {
        commit("setNotifications", resp.data);
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });

const authToken = ({ dispatch, commit }, payload) =>
  new Promise((resolve, reject) => {
    commit("resetHostsState");
    commit("resetStatsState");
    commit("resetDashboardState");
    commit("authRequest");
    const { token } = payload;
    axios.defaults.headers.common.Authorization = token;
    axios({
      url: apiUrl("auth/me/"),
      method: "GET",
    })
      .then((resp) => {
        const { login } = resp.data;
        commit("authSuccess", { token, login });

        // load manager info
        dispatch("managerRequest")
          .then((response) => {
            commit("setManager", response.data);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });

        // load notifications
        // dispatch("notificationsRequest");
      })
      .catch((err) => {
        commit("authError", err);
        localStorage.removeItem("token");
        localStorage.removeItem("login");
        reject(err.response.data.error.message);
      });
  });

const authRequest = ({ dispatch, commit }, payload) =>
  new Promise((resolve, reject) => {
    commit("resetHostsState");
    commit("resetStatsState");
    commit("resetDashboardState");
    commit("authRequest");
    axios({
      url: apiUrl(`auth/${payload.action}/`),
      data: payload.user,
      method: payload.method || "POST",
    })
      .then((resp) => {
        const token = `Token ${resp.data.token}`;
        axios.defaults.headers.common.Authorization = token;
        axios({
          url: apiUrl("auth/me/"),
          method: "GET",
        })
          .then((response) => {
            const { login } = response.data;
            commit("authSuccess", { token, login });
          })
          .catch((err) => {
            commit("authError", err);
            localStorage.removeItem("token");
            localStorage.removeItem("login");
            reject(err.response.data.error.message);
          });

        // load manager info
        dispatch("managerRequest")
          .then((response) => {
            commit("setManager", response.data);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      })
      .catch((err) => {
        commit("authError", err);
        localStorage.removeItem("token");
        localStorage.removeItem("login");
        reject(err.response ? err.response.data.error.message : err);
      });
  });

const authDataRequest = (_, payload) =>
  new Promise((resolve, reject) => {
    const { data } = payload;
    axios({
      url: apiUrl(`auth/${payload.action}/`),
      ...(data && { data }),
      method: payload.method || "GET",
    })
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        reject(err.data.error.message);
      });
  });

const logoutRequest = ({ commit }) =>
  new Promise((resolve) => {
    commit("logoutRequest");
    delete axios.defaults.headers.common.Authorization;
    resolve();
  });

const authModule = {
  state: {
    status: "",
    login: localStorage.getItem("login") || null,
    token: localStorage.getItem("token") || null,
    manager: JSON.parse(localStorage.getItem("manager")) || null,
    notifications: [],
    recentRulesAccepted: null,
  },
  mutations: {
    authRequest(state) {
      state.status = "loading";
    },
    authSuccess(state, { token, login }) {
      localStorage.setItem("token", token);
      localStorage.setItem("login", login);
      axios.defaults.headers.common.Authorization = token;

      state.status = "success";
      state.token = token;
      state.login = login;
    },
    logoutRequest(state) {
      localStorage.removeItem("token");
      localStorage.removeItem("login");
      localStorage.removeItem("manager");

      state.status = "";
      state.token = null;
      state.login = null;
      state.notifications = [];
      state.recentRulesAccepted = null;
    },
    setManager(state, manager) {
      localStorage.setItem("manager", JSON.stringify(manager));
      state.manager = manager;
    },
    setRecentRulesAccepted(state, val) {
      state.recentRulesAccepted = val;
    },
    setNotifications(state, manager) {
      state.notifications = manager;
    },
    authError(state) {
      state.status = "error";
    },
  },
  actions: {
    authRequest,
    authToken,
    logoutRequest,
    authDataRequest,
    managerRequest,
    notificationsRequest,
  },
  getters: {
    isAuthenticated: (state) => !!state.login,
    login: (state) => state.login,
    manager: (state) => state.manager,
    notifications: (state) => state.notifications,
    getRecentRulesAccepted: (state) => state.recentRulesAccepted,
  },
};

export default authModule;
