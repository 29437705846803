import Vue from "vue";
import VueRouter from "vue-router";

// navigation guards
import ifNotAuthenticated from "./nav-guards/ifNotAuthenticated";

// critical prefetched views
import Login from "../views/LoginPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "stats",
    component: () =>
      import(/* webpackChunkName: "stats" */ "../views/StatsPage.vue"),
    meta: { enableLayout: true },
  },
  {
    path: "/profile-edit",
    name: "profile-edit",
    component: () =>
      import(/* webpackChunkName: "profile-edit" */ "../views/ProfileEdit.vue"),
    meta: { enableLayout: true },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/password-reset",
    name: "password-reset",
    component: () =>
      import(
        /* webpackChunkName: "password-reset" */ "../views/PasswordReset.vue"
      ),
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/help",
    name: "help",
    component: () =>
      import(/* webpackChunkName: "help" */ "../views/HelpPage.vue"),
    meta: { enableLayout: true },
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
