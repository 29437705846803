import axios from "axios";
import apiUrl from "@/utils/apiUrl";

const statsRequest = ({ commit }, payload) =>
  new Promise((resolve, reject) => {
    axios({
      url: apiUrl("statistics/", payload.queryParams),
      method: "GET",
    })
      .then((resp) => {
        const { data } = resp;
        commit("set", {
          key: payload.dashbordStat ? "dashbordStatistics" : "statistics",
          data,
        });
        commit("set", {
          key: payload.dashbordStat
            ? "dashbordStatisticsGroup"
            : "statisticsGroup",
          data: payload.queryParams.group,
        });
        resolve(data);
      })
      .catch((err) => {
        const { data } = err;
        reject(data);
      });
  });

function initialState() {
  return {
    dashbordStatistics: [],
    selectedRange: null,
    financesSelectedRange: null,
    statistics: [],
    statisticsChart: [],
    statisticsChartMetric: null,
    statisticsGroup: null,
    dashbordStatisticsGroup: null,
  };
}

const statsModule = {
  state: initialState(),
  mutations: {
    set(state, { key, data }) {
      state[key] = data;
    },
    resetStatsState(state) {
      const is = initialState();
      Object.keys(state).forEach((key) => {
        state[key] = is[key];
      });
    },
  },
  actions: {
    statsRequest,
    setSelectedRange({ commit }, payload) {
      return new Promise((resolve) => {
        commit("set", { key: "selectedRange", data: payload });
        resolve();
      });
    },
  },
  getters: {
    getSelectedRange: (state) => state.selectedRange,
    getStatistics: (state) => state.statistics,
    getStatisticsGroup: (state) => state.statisticsGroup,
  },
};

export default statsModule;
