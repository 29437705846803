<template>
  <header class="header col-12">
    <div class="header__caption row align-items-center">
      <div class="col-5">
        <router-link to="dashboard">
          <img class="header__logo-img" :src="logoImg" alt="logo" />
        </router-link>
      </div>
      <YourManager class="col-4" :manager="$store.getters.manager || manager" />
      <div class="col-3"><UserPlate :username="$store.getters.login" /></div>
    </div>
    <NotificationsCarousel class="header__notifications-carousel" />
  </header>
</template>
<script>
import { Notification } from "element-ui";

import UserPlate from "@/components/controls/UserPlate.vue";
import YourManager from "@/components/controls/YourManager.vue";
import NotificationsCarousel from "@/components/controls/NotificationsCarousel.vue";

export default {
  name: "TheHeader",
  components: {
    UserPlate,
    YourManager,
    NotificationsCarousel,
  },
  data() {
    return {
      manager: {
        name: "Александра Рахимова",
        email: "alexandra.rakh@umedia.group",
      },
      url: "",
      modalShown: false,
    };
  },
  computed: {
    logoImg() {
      return process.env.VUE_APP_LOGO_IMG;
    },
    hostModalShown: {
      set(val) {
        this.$store.commit("hostModalShown", val);
      },
      get() {
        return this.$store.getters.hostModalShown;
      },
    },
  },
  methods: {
    submitForm() {
      const urlexpr =
        /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;
      const { url } = this;
      if (!urlexpr.test(url)) {
        Notification.error({
          title: "Ошибка!",
          message: "Некорректный url",
        });
        return;
      }
      this.$store.dispatch("addHost", { url }).then(
        () => {
          Notification.success({
            title: "Успешно!",
            message: `Вы успешно добавили площадку ${url}`,
          });

          this.$store.commit("hostModalShown", false);
          this.url = "";
        },
        () => {
          Notification.error({
            title: "Ошибка!",
            message: "Произошла ошибка при добавлении площадки",
          });
        }
      );
    },
  },
  mounted() {
    this.$store.dispatch("managerRequest").then((response) => {
      this.$store.commit("setManager", response.data);
    });
  },
};
</script>

<style lang="scss">
.header__logo-img {
  max-height: 50px;
}
</style>
