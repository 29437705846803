<template>
  <footer class="footer col-12 mt-5 py-4">
    <div class="row">
      <div class="col"></div>
      <div class="col-10 display-flex">
        <div class="row">
          <div class="col-4">
            <img src="/icons/logo-dimmed.svg" alt="Utraff.com" />
          </div>
          <div class="col-8 support">
            <a
              :href="`mailto:${email}?subject=Поддержка пользователя ${$store.getters.login}`"
            >
              Техническая поддержка <br />
              {{ email }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "TheFooter",
  data() {
    return {
      email: "account@umedia.group",
    };
  },
};
</script>

<style lang="scss">
@import "@/styles/variables";

.footer {
  border-top: 1px solid $c-border-light;

  .support {
    display: flex;
    justify-content: flex-end;

    a {
      position: relative;
      display: inline-block;

      &:before {
        content: "";
        display: block;
        position: absolute;
        right: calc(100% + 6px);
        top: 3px;
        background-color: $c-dropdown-border-active;
        width: 10px;
        height: 10px;
        border-radius: 10px;
      }
    }
  }

  a {
    color: $c-dropdown-border-active;
    text-decoration: none;
    line-height: 1.4;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
