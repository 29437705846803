<template>
  <router-link
    :to="link"
    :disabled="disabled"
    :class="{
      'nav-button--dashboard': type === 'dashboard',
      'nav-button--with-icon': icon,
      'nav-button--disabled': disabled,
    }"
    class="nav-button"
    :style="bgi"
  >
    <span>{{ label }}</span>
  </router-link>
</template>

<script>
export default {
  name: "NavButton",
  props: {
    label: String,
    link: String,
    icon: String,
    disabled: Boolean,
    type: {
      default: "common",
      type: String,
    },
  },
  computed: {
    bgi() {
      return this.icon ? `background-image: url(icons/${this.icon}.svg)` : null;
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/variables";

.nav-button {
  display: block;
  padding: 10px;
  color: $c-text-primary;
  text-decoration: none;
  font-size: 1.107rem;
  background-color: transparent;
  border: none;
  border-radius: 7px;

  &--dashboard {
    padding: 15px 15px 15px 55px;
    color: $c-primary;
    background-image: url(../../assets/icons/dashboard.svg);
    background-repeat: no-repeat;
    background-position: 15px 50%;

    &:not(.router-link-exact-active):hover {
      background-color: #{$c-white}55;
    }
  }

  &--disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &--with-icon {
    padding-left: 42px;
  }

  &--dashboard,
  &--with-icon {
    background-repeat: no-repeat;
    background-position: 15px 50%;
  }

  &:not(&--dashboard).router-link-active {
    background-color: $c-white;
  }

  &--dashboard.router-link-exact-active {
    background-color: $c-white;
  }

  &:not(.router-link-active):hover {
    background-color: #{$c-white}55;
  }

  & + & {
    margin-top: 5px;
  }
}
</style>
