<template>
  <div class="user-plate">
    <div>
      <span class="user-plate__username">{{ username }}</span>
    </div>
    <div>
      <a @click="logout()" class="user-plate__logout">Выйти</a>
    </div>
    <div>
      <router-link to="profile-edit" class="user-plate__profile-edit"
        >Редактировать информацию</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "UserPlate",
  props: {
    username: String,
  },
  methods: {
    logout() {
      this.$store.dispatch("logoutRequest").then(() => {
        this.$router.push("login");
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/variables";

.user-plate {
  display: inline-block;
  position: relative;
  line-height: 1.2;
  font-size: 0.9rem;

  &__username {
    display: inline-block;
    color: $c-black;
    font-size: 1.2em;
    padding: 2px 8px 0;
  }

  &__profile-edit {
    display: inline-block;
    color: $c-border-base;
    text-decoration: none;
    padding: 2px 8px;

    &.router-link-active {
      color: $c-primary;
    }
  }

  &__logout {
    color: $c-border-base;
    cursor: pointer;
    text-decoration: none;
    padding: 2px 11px 3px 8px;
    background-image: url(../../assets/icons/logout.svg);
    background-repeat: no-repeat;
    background-position: 100% 50%;
  }

  &__payouts {
    color: $c-border-base;
    text-decoration: none;
    padding: 3px 8px 3px 23px;
    background-image: url(../../assets/icons/payouts.svg);
    background-repeat: no-repeat;
    background-position: 8px 60%;

    &.router-link-active {
      background-image: url(../../assets/icons/payouts-active.svg);
      color: $c-primary;
    }
  }
}
</style>
