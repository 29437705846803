import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

import authModule from "@/store/modules/auth.module";
import hostsModule from "@/store/modules/hosts.module";
import statsModule from "@/store/modules/stats.module";
import dashboardModule from "@/store/modules/dashboard.module";

import apiUrl from "@/utils/apiUrl";

Vue.use(Vuex);

const commonRequest = (_, payload) =>
  new Promise((resolve, reject) => {
    const { url, queryParams } = payload;

    axios({
      url: apiUrl(url, queryParams),
      method: "GET",
    })
      .then((resp) => {
        const { data } = resp;
        resolve(data);
      })
      .catch((err) => {
        const { data } = err;
        reject(data);
      });
  });

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {
    commonRequest,
  },
  modules: {
    authModule,
    hostsModule,
    statsModule,
    dashboardModule,
  },
});
