<template>
  <div class="sidebar">
    <NavButton link="/" label="Статистика" type="dashboard" />
    <br />
    <NavButton
      v-if="isVisible"
      style="position: absolute; bottom: 0"
      link="help"
      label="Помощь"
      icon="help"
    />
  </div>
</template>

<script>
import NavButton from "@/components/controls/NavButton.vue";

export default {
  name: "TheSidebar",
  components: {
    NavButton,
  },
  computed: {
    isVisible() {
      return +process.env.VUE_APP_SHOW_HELP;
    },
  },
};
</script>

<style scoped lang="scss"></style>
