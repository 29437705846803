import axios from "axios";
import apiUrl from "@/utils/apiUrl";

const getHosts = ({ commit }) =>
  new Promise((resolve, reject) => {
    axios({
      url: apiUrl("host/"),
      method: "GET",
    })
      .then((resp) => {
        commit("set", { key: "hostsList", val: resp.data });
        resolve(resp.data);
      })
      .catch((err) => {
        reject(err.response ? err.response.data.error.message : err);
      });
  });

const addHost = ({ commit }, data) =>
  new Promise((resolve, reject) => {
    axios({
      url: apiUrl("host/"),
      method: "POST",
      data,
    })
      .then((resp) => {
        commit("addToArray", { key: "hostsList", val: resp.data });
        resolve(resp.data);
      })
      .catch((err) => {
        reject(err.response ? err.response.data.error.message : err);
      });
  });

const setElua = ({ commit }, data) =>
  new Promise((resolve, reject) => {
  const { eula_accepted } = data; // eslint-disable-line
    axios({
      url: apiUrl(`host/${data.id}/`),
      method: "PATCH",
      data: { eula_accepted },
    })
      .then((resp) => {
        commit("updateListEl", resp.data);
        resolve(resp);
      })
      .catch((err) => {
        reject(err.response ? err.response.data.error.message : err);
      });
  });

function initialState() {
  return {
    hostsList: [],
    addHostModalShown: false,
  };
}

const hostsModule = {
  state: initialState(),
  mutations: {
    set(state, { key, val }) {
      state[key] = val;
    },
    addToArray(state, { key, val }) {
      state[key] = [...state[key], val];
    },
    hostModalShown(state, val) {
      state.addHostModalShown = val;
    },
    updateListEl(state, el) {
      const targetIndex = state.hostsList.findIndex(
        (element) => element.id === el.id
      );
      if (targetIndex > -1) state.hostsList[targetIndex] = el;
      else state.hostsList = [...state.hostsList, el];
    },
    resetHostsState(state) {
      const is = initialState();
      Object.keys(state).forEach((key) => {
        state[key] = is[key];
      });
    },
  },
  actions: {
    getHosts,
    setElua,
    addHost,
  },
  getters: {
    getHosts: (state) => state.hostsList,
    hostModalShown: (state) => state.addHostModalShown,
  },
};

export default hostsModule;
