export default function getUrlParams(search) {
  const hashes = search.slice(search.indexOf("?") + 1).split("&");
  const params = {};
  // eslint-disable-next-line array-callback-return
  hashes.map((hash) => {
    const [key, val] = hash.split("=");
    params[key] = decodeURIComponent(val);
  });
  return params;
}
