var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"notifications-carousel"},[_c('carousel',{attrs:{"perPage":3,"navigationEnabled":true,"navigationPrevLabel":'',"navigationNextLabel":'',"paginationEnabled":false}},_vm._l((_vm.$store.getters.notifications),function(notification){return _c('slide',{key:notification.id,staticClass:"colored-notification",class:{
        'colored-notification--success': ['info'].includes(
          notification.level
        ),
        'colored-notification--danger': ['danger', 'warning'].includes(
          notification.level
        ),
      },on:{"slide-click":function($event){return _vm.go()}}},[_vm._v(" "+_vm._s(notification.title)+" ")])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }