import axios from "axios";
import apiUrl from "@/utils/apiUrl";

function capitalize(word) {
  return `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
}

const dashboardRequest = ({ commit }, payload) =>
  new Promise((resolve, reject) => {
    const { kind, stateKey, queryParams } = payload;

    axios({
      url: apiUrl(`dashboard/${kind}/`, queryParams),
      method: "GET",
    })
      .then((resp) => {
        const { data } = resp;
        commit("set", { key: stateKey, data });
        resolve(data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });

const getDashboards = ({ dispatch }, payload) =>
  new Promise((resolve) => {
    const { dashboardEarningsConfig, dashboardFinanceConfig } = payload;

    const earnings = dashboardEarningsConfig.reduce(
      (acc, val) => [
        ...acc,
        dispatch("dashboardRequest", {
          kind: "earnings",
          stateKey: `dashboardEarnings${capitalize(val.metric)}`,
          queryParams: val,
        }),
      ],
      []
    );

    const finance = dispatch("dashboardRequest", {
      kind: "finance",
      stateKey: "dashboardFinance",
      queryParams: dashboardFinanceConfig,
    });

    Promise.all([...earnings, finance]).then(() => {
      resolve();
    });
  });

function initialState() {
  return {
    dashboardFinance: null,
    dashboardEarningsBids: null,
    dashboardEarningsCpm: null,
  };
}

const dashboardModule = {
  state: initialState(),
  mutations: {
    set(state, { key, data }) {
      state[key] = data;
    },
    resetDashboardState(state) {
      const is = initialState();
      Object.keys(state).forEach((key) => {
        state[key] = is[key];
      });
    },
  },
  actions: {
    dashboardRequest,
    getDashboards,
  },
  getters: {
    getDashboardFinance: (state) => state.dashboardFinance,
    getDashboardEarningsBids: (state) => state.dashboardEarningsBids,
    getDashboardEarningsCpm: (state) => state.dashboardEarningsCpm,
  },
};

export default dashboardModule;
