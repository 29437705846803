<template>
  <div class="login-page">
    <BaseModal :shown="true" unclosable>
      <h2 slot="header" class="offset-3">Вход в систему</h2>
      <form slot="body" @submit.prevent="submitForm()">
        <div class="row pt-4 py-3">
          <div class="col-3 align-self-center form-label">Логин/Email</div>
          <div class="col-9">
            <input
              type="text"
              autocomplete="username"
              :class="{ 'form-input--is-invalid': $v.login.$error }"
              v-model="login"
              class="form-input"
            />
          </div>
        </div>
        <div class="row py-3">
          <div class="col-3 align-self-center form-label">Пароль</div>
          <div class="col-9">
            <input
              type="password"
              autocomplete="current-password"
              :class="{ 'form-input--is-invalid': $v.password.$error }"
              v-model="password"
              class="form-input"
            />
          </div>
        </div>
        <div class="row py-3">
          <div class="offset-3 col-9">
            <div class="d-flex">
              <button type="submit" class="form-btn col-6">Войти</button>
              <router-link
                :to="{
                  name: 'password-reset',
                  query: $route.query,
                }"
                class="col-6 align-self-center d-inline-block modal-link"
              >
                Восстановить пароль
              </router-link>
            </div>
          </div>
        </div>
        <div class="row pt-3 pb-3">
          <section class="col offset-3 form-error-list">
            <div
              v-if="
                (this.submitted && formErr.hasOwnProperty('login')) ||
                formErr.hasOwnProperty('password')
              "
            >
              Неверный логин или пароль
            </div>
            <div
              v-if="$v.$error && (!$v.login.required || !$v.password.required)"
            >
              Введите учетные данные
            </div>
          </section>
        </div>
      </form>
      <div slot="footer">
        <!--<div class="row">
          <div class="offset-3 col">
            Нет аккаунта?
            <router-link
              :to="{ name: 'register', query: $route.query }"
              class="col align-self-center modal-link"
              >Зарегистрироваться</router-link
            >
          </div>
        </div>-->
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import BaseModal from "@/components/BaseModal.vue";

export default {
  name: "LoginPage",
  components: {
    BaseModal,
  },
  data() {
    return {
      loading: false,
      login: "",
      password: "",
      formErr: {},
      submitted: false,
    };
  },
  computed: {},
  validations: {
    login: {
      required,
    },
    password: {
      required,
    },
  },
  methods: {
    submitForm() {
      this.submitted = true;
      this.formErr = {};

      this.$v.$touch();

      if (this.$v.$invalid) return;

      if (!this.loading) {
        this.loading = true;
        const { login, password } = this;
        this.$store
          .dispatch("authRequest", {
            action: "token",
            user: { login, password },
          })
          .then(
            () => {
              this.$router.push("/");
              this.loading = false;
            },
            (error) => {
              if (error.data) this.formErr = error.data.error.message;
              this.loading = false;
            }
          );
      }
    },
  },
};
</script>
