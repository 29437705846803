import { render, staticRenderFns } from "./UserPlate.vue?vue&type=template&id=7e098033&"
import script from "./UserPlate.vue?vue&type=script&lang=js&"
export * from "./UserPlate.vue?vue&type=script&lang=js&"
import style0 from "./UserPlate.vue?vue&type=style&index=0&id=7e098033&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports