<template>
  <div id="app">
    <div class="wrapper">
      <TheHeader v-if="$route.meta.enableLayout" />
      <main class="main-wrapper row" style="min-height: 150px">
        <TheSidebar class="col-2" v-if="$route.meta.enableLayout" />
        <div class="content col-10">
          <router-view />
        </div>
      </main>
      <TheFooter v-if="$route.meta.enableLayout && isVisible" />
    </div>
  </div>
</template>

<script>
import TheHeader from "@/components/TheHeader.vue";
import TheFooter from "@/components/TheFooter.vue";
import TheSidebar from "@/components/TheSidebar.vue";

export default {
  name: "App",
  components: {
    TheHeader,
    TheSidebar,
    TheFooter,
  },
  // mounted() {
  //   if (
  //     !this.$store.getters.notifictions &&
  //     this.$store.getters.isAuthenticated
  //   ) {
  //     this.$store.dispatch("notificationsRequest");
  //   }
  // },
  computed: {
    isVisible() {
      return +process.env.VUE_APP_SHOW_FOOTER;
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/main";
</style>
