import store from "../../store";

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }

  next("/");
};

export default ifNotAuthenticated;
